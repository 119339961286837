<template>
    <div class="cd-body">
        <!-- navbar -->
        <div class="sm:px-2 px-0 py-4 flex items-center justify-between">
            <div class="flex">
                <div class="mr-4 flex items-center text-primary cursor-pointer hover:font-semibold" @click="$router.go(-1)">
                    <feather-icon class="mr-1" icon="ChevronLeftIcon" svgClasses="h-4 w-5"></feather-icon>
                    <span>{{ $t('membersMgmt.prev') }}</span>
                </div>
                <h3>{{ $t('membersMgmt.title') }}</h3>
            </div>
            <div v-if="windowWidth <= 576">
                <vs-button class="cd-button-2w-icon" type="flat" icon-pack="feather" icon="icon-filter" @click="showFilter">{{ $t('membersMgmt.filter') }}</vs-button>
            </div>
        </div>
        <vs-row>
            <!-- filter -->
            <vs-col vs-w="3" vs-xs="12">
                <vs-sidebar class="cd-sidebar sm:px-0 px-4" :style="'min-height: ' + (windowHeight - 125) + 'px;' + windowWidth <= 600 ? '' : 'background-color: #efefef;'" :static-position="menuActive" :hidden-background="true" v-model="menuActive">
                    <VuePerfectScrollbar class="p-4" :style="windowWidth <= 600 ? 'height: 62vh;' : ''" :settings="settings">
                        <!-- query -->
                        <div class="mb-base">
                            <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                        </div>
                        <!-- unit -->
                        <div class="mb-base" v-if="unitList.length > 0">
                            <vs-radio class="w-full mb-3" v-model="unit" :vs-name="'unit'" :vs-value="null" @change="getMemberList">{{ $t('membersMgmt.unit') }}</vs-radio>
                            <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in unitList" :key="index" v-model="unit" :vs-name="'unit' + index" :vs-value="item.enterprise_id" @change="getMemberList">{{ item.name }}</vs-radio>
                        </div>
                        <!-- tags -->
                        <div class="mb-base" v-if="crmTagsList.length > 0">
                            <vs-radio class="w-full mb-3" v-model="tags" :vs-name="'tags'" :vs-value="null" @change="getMemberList">{{ $t('membersMgmt.tags') }}</vs-radio>
                            <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in crmTagsList" :key="index" v-model="tags" :vs-name="'tags' + index" :vs-value="item" @change="getMemberList">{{ item }}</vs-radio>
                        </div>
                        <!-- sicks -->
                        <div class="mb-base" v-if="sickList.length > 0">
                            <vs-radio class="w-full mb-3" v-model="sicks.id" :vs-name="'sicks'" :vs-value="null" @change="getMemberList">{{ $t('membersMgmt.disease') }}</vs-radio>
                            <vs-radio class="w-full my-2 ml-4" v-for="(item, index) in sickList" :key="index" v-model="sicks.id" :vs-name="'sicks' + index" :vs-value="item.mh_item_id" @change="getMemberList">{{ lang == 'en' ? item.ename : item.note }}</vs-radio>
                        </div>
                    </VuePerfectScrollbar>
                    <vs-divider v-if="windowWidth <= 600" />
                    <!-- 篩選按鈕 -->
                    <div class="mb-4 flex justify-around" v-if="windowWidth <= 600">
                        <vs-button type="border" @click="reset">{{ $t('popup.reset') }}</vs-button>
                        <vs-button class="cd-button-filled" @click="showFilter">{{ $t('popup.confirm') }}</vs-button>
                    </div>
                </vs-sidebar>
            </vs-col>
            <!-- content -->
            <vs-col class="cd-apptmgmt-content" vs-w="9" vs-xs="12" v-if="!menu">
                <div class="flex flex-wrap pt-2 sm:px-4 px-2 items-center">
                    <span class="mr-4">{{ total }} {{ $t('membersMgmt.dataLength') }}</span>
                    <vs-chip
                        class="mr-4"
                        style="color: #111"
                        closable
                        @click="
                            query = null
                            getMemberList()
                        "
                        v-if="query"
                    >
                        {{ query }}
                    </vs-chip>
                    <vs-chip
                        class="mr-4"
                        style="color: #111"
                        closable
                        @click="
                            unit = null
                            getMemberList()
                        "
                        v-if="unit"
                    >
                        {{ getUnitName(unit) }}
                    </vs-chip>
                    <vs-chip
                        class="mr-4"
                        style="color: #111"
                        closable
                        @click="
                            tags = null
                            getMemberList()
                        "
                        v-if="tags"
                    >
                        {{ tags }}
                    </vs-chip>
                    <vs-chip
                        class="mr-4"
                        style="color: #111"
                        closable
                        @click="
                            sicks.id = null
                            getMemberList()
                        "
                        v-if="sicks.id"
                    >
                        {{ getSickName(sicks.id) }}
                    </vs-chip>
                </div>
                <div class="p-2 flex flex-wrap" v-if="membersList.length > 0">
                    <div class="sm:px-4 px-2 sm:py-4 py-2 m-2 sm:w-2/5 w-full shadow-md overflow-hidden" v-for="(item, index) in membersList" :key="index">
                        <div class="flex justify-between">
                            <!-- pid 姓名 -->
                            <div class="whitespace-no-wrap">
                                <span class="font-semibold text-lg" :class="item.authorize == 1 ? 'cursor-pointer text-primary' : 'text-grey'" @click="goToMr(item)">{{ item.fullname }}, {{ getSex(item.sex) }} {{ getAge(item.birthday) }}</span>
                            </div>
                            <!-- CTA -->
                            <div class="flex whitespace-no-wrap">
                                <div class="mr-2 flex items-center text-primary cursor-pointer hover:font-semibold" @click.stop="showChatPopup(item.mid, item.fullname)">
                                    <feather-icon class="mx-1" icon="MessageCircleIcon" svgClasses="w-5 h-5"></feather-icon>
                                    <span class="whitespace-no-wrap" v-if="windowWidth > 600">{{ $t('membersMgmt.sendMsg') }}</span>
                                </div>
                                <div @click="goToMrToggle = false">
                                    <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                                        <vs-button class="" type="flat" icon-pack="feather" icon="icon-more-vertical"></vs-button>
                                        <vs-dropdown-menu>
                                            <div class="flex whitespace-no-wrap p-2" style="min-width: 11rem">
                                                <ul class="w-full">
                                                    <li class="flex p-2" :class="item.authorize == 1 ? 'cursor-pointer hover:bg-primary hover:text-white' : 'text-grey'" @click="openCallPopup(item)">
                                                        <span>{{ $t('membersMgmt.call') }}</span>
                                                    </li>
                                                    <li class="flex p-2" :class="item.authorize == 1 ? 'cursor-pointer hover:bg-primary hover:text-white' : 'text-grey'" @click="showApptPopup(0, item)">
                                                        <span>{{ $t('membersMgmt.agent') }}</span>
                                                    </li>
                                                    <li class="flex p-2" :class="item.authorize == 1 ? 'cursor-pointer hover:bg-primary hover:text-white' : 'text-grey'" @click="showApptPopup(1, item)">
                                                        <span>{{ $t('membersMgmt.special') }}</span>
                                                    </li>
                                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showCasePopup(item.pid, item.fullname)">
                                                        <span>{{ $t('membersMgmt.addCase') }}</span>
                                                    </li>
                                                    <li class="flex p-2" :class="item.authorize == 1 ? 'cursor-pointer hover:bg-primary hover:text-white' : 'text-grey'" @click="showGoToMrToggle(item)">
                                                        <span>{{ $t('membersMgmt.goToMr') }}</span>
                                                        <feather-icon icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1" />
                                                    </li>
                                                    <div class="pl-2 ml-2 cd-dropdown-children" v-if="goToMrToggle == true">
                                                        <ul class="" v-for="(data, index) in emrConfig" :key="index">
                                                            <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="goToMr(item, data[0])" v-if="data[1].show == true">
                                                                <span>{{ geti18n(data[1].i18n) }}</span>
                                                            </li>
                                                            <div class="pl-2 ml-2 cd-dropdown-children" v-if="data[0] == 'dicomData'">
                                                                <ul class="" v-for="(data2, index) in dicomConfig" :key="index">
                                                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="goToMr(item, 'dicomData', data2[0])" v-if="data2[1].show == true">
                                                                        <span>{{ geti18n(data2[1].i18n) }}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="openProductPopup(item.pid, 0, item.pname)">
                                                        <span>{{ $t('emr.appt.Recommend') }}</span>
                                                    </li>
                                                    <li class="flex p-2" :class="item.authorize == 1 ? 'cursor-pointer hover:bg-primary hover:text-white' : 'text-grey'" @click="showUploadPopup(item)">
                                                        <span>{{ $t('membersMgmt.upload') }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </vs-dropdown-menu>
                                    </vs-dropdown>
                                </div>
                            </div>
                        </div>
                        <!-- 帳號 手機 -->
                        <div class="flex whitespace-no-wrap">
                            <div class="flex items-center" :class="item.authorize == 1 ? '' : 'text-grey'">
                                <feather-icon class="mr-1" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                                <span>{{ item.account }}</span>
                            </div>
                            <div class="ml-4 flex items-center text-primary" v-if="item.mobile">
                                <feather-icon class="mr-1" icon="PhoneIcon" svgClasses="w-4 h-4"></feather-icon>
                                <a :href="getPhone(item.mobile)">{{ item.mobile }}</a>
                            </div>
                        </div>
                        <!-- 病患未授權 -->
                        <div class="whitespace-no-wrap text-danger" v-if="item.authorize == 0">
                            <span class="truncate">{{ $t('membersMgmt.noAuth') }}</span>
                        </div>
                        <!-- 個人病史 -->
                        <div class="whitespace-no-wrap" v-else>
                            <div class="truncate" v-if="item.person_sick != ''">
                                <span v-if="lang == 'en'">
                                    {{ item.person_sick_en }}
                                </span>
                                <span v-for="sick in item.person_sick" :key="sick.id" v-else>
                                    {{ sick.name }}
                                </span>
                            </div>
                            <div v-else>
                                <span>{{ $t('membersMgmt.noDisease') }}</span>
                            </div>
                        </div>
                        <!-- 單位 -->
                        <div class="whitespace-no-wrap truncate" :class="item.authorize == 1 ? '' : 'text-grey'">
                            <span v-for="(name, index2) in item.enterprise_name" :key="index2">
                                {{ name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-8" v-else>
                    {{ $t('membersMgmt.noData') }}
                </div>
                <!-- page -->
                <div class="sm:px-4 px-2 my-3 w-full flex justify-start" v-if="total != 0">
                    <vs-pagination :max="windowWidth <= 600 ? 5 : 7" :total="Math.ceil(total / limit)" v-model="page" @change="getMemberList($event, page)" v-if="total != 0"></vs-pagination>
                </div>
            </vs-col>
        </vs-row>
        <!-- 選擇通話對象視窗 -->
        <!-- <vs-popup :active.sync="callPopup" :title="$t('membersMgmt.callTitle')">
            <div style="max-height: 400px;">
                <vs-divider>{{$t('member.patient')}}</vs-divider>
                <div class="cd-form-group flex justify-between items-center">
                    <p class="cd-form-group">{{callPopupPayload}}</p>
                    <vs-button class="cd-button-2w-icon" icon-pack="feather" icon="icon-video" @click="call(0, callPopupPayload.mid)">通話</vs-button>
                    <call :selectedPid="callPopupPayload.pid" :selectedPname="callPopupPayload.name" :disabled="false" :selectdid="'patient'" :partnerid="partnerid" :interval="0" :finish="0" :callPopup="callPopup" @closeCallPopup="closeCallPopup"></call>
                </div>
                <vs-divider>{{'('+onlineDoc+'/'+contactTotal+') '+$t('member.transfer')}}</vs-divider>
                <div class="cd-form-group flex justify-between items-center" v-for="(item, key) in contacts" :key="key">
                    <div class="whitespace-no-wrap truncate w-32">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="whitespace-no-wrap truncate w-full px-4 text-left">
                        <span>{{item.di_name}}</span>
                    </div>
                    <div class="whitespace-no-wrap">
                        <vs-button class="cd-button-2w-icon" icon-pack="feather" icon="icon-video" @click="call(item)">通話</vs-button>
                        <call :selectedPid="callPopupPayload.pid" :selectedPname="callPopupPayload.name" :disabled="!item.num" :selectdid="item.did" :partnerid="partnerid" :interval="0" :finish="0" :callPopup="callPopup" @closeCallPopup="closeCallPopup"></call>
                    </div>
                </div>
            </div>
        </vs-popup> -->
        <!-- 掛號視窗 -->
        <appt-btn :activePopup="openApptPopup" :mode="apptMode" :patientData="patient" @closeApptPopup="closeApptPopup"></appt-btn>
        <!-- 訊息視窗 -->
        <send-chat :chatPopup="openChatPopup" :smid="parseInt(midChat)" :sname="nameChat" @closeChat="closeChatPopup"></send-chat>
        <!-- 上傳醫療影像視窗 -->
        <upload-popup :uploadPopupActive="openUploadPopup" :spid="parseInt(pidUpload)" :spname="nameUpload" @closePopup="closeUploadPopup"></upload-popup>
        <!-- 新增案件視窗 -->
        <add-case :spid="parseInt(pidCase)" :spname="pnameCase" :newCasePopupActive="openCasePopup" @closePopup="closeCasePopup"></add-case>
        <!-- 推薦商品 -->
        <product-popup :productPopupActive="productPopupActive" @closePopup="productClose" :pid="recommandPid" :sn_id="recommsnid" :pname="recommpname"></product-popup>
        <!-- 通話視窗 -->
        <!-- <call-to :callPopupActive="callPopup" :pid="callPayload.spid" @closePopup="closeCallPopup"></call-to> -->
        <call-to :callPopupActive="callPopup" :pid="callPid.toString()" @closePopup="closeCallPopup"></call-to>
    </div>
</template>

<script>
import { getMemberFilter, getMemberList, QueryDoctorListByPartner } from '@/api/user'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import sendChat from '@/components/sendChat'
// import call from '@/components/call'
import ApptBtn from '../components/apptBtn.vue'
import uploadPopup from '@/components/uploadPopup'
import addCase from '@/components/addCase'
import { debounce } from 'lodash'
import productPopup from '@/components/productPopup.vue'
import callTo from '@/components/callTo'

export default {
    components: {
        VuePerfectScrollbar,
        sendChat,
        // call,
        ApptBtn,
        uploadPopup,
        addCase,
        productPopup,
        callTo,
    },
    data() {
        return {
            unitList: [],
            crmTagsList: [],
            sickList: [],
            unit: null,
            tags: null,
            sicks: {
                id: null,
            },
            query: null,
            limit: 30,
            page: 1,
            membersList: [],
            total: 0,
            flag: false,
            openChatPopup: false,
            openApptPopup: false,
            openUploadPopup: false,
            openCasePopup: false,
            callPopup: false,
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            midChat: 0,
            nameChat: '',
            pidCase: 0,
            pnameCase: '',
            pidUpload: 0,
            nameUpload: '',
            goToMrToggle: false,
            // callPopupPayload: [],
            callPayload: [],
            contacts: [],
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            menu: false,
            recommandPid: 0,
            recommsnid: 0,
            recommpname: '',
            allowshopstatus: '',
            serviceNote: '',
            productPopupActive: false,
            callPid: '',
        }
    },
    watch: {
        query() {
            this.getMemberList()
        },
        unit(v) {
            localStorage.setItem('selectedUnit', JSON.stringify(v))
        },
    },
    computed: {
        did() {
            return this.$store.getters.did
        },
        partnerid() {
            return this.$store.getters.partner
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        windowHeight() {
            return window.innerHeight
        },
        lang() {
            return this.$i18n.locale
        },
        emrConfig() {
            let data = JSON.parse(localStorage.getItem('his_config')).emr
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        dicomConfig() {
            let data = JSON.parse(localStorage.getItem('his_config')).emr.dicomData.contents
            _.pull(data, _.find(data, { show: false }))
            let map = new Map()
            for (let k in data) {
                map.set(k, data[k])
            }
            let ArrayObj = Array.from(map)
            ArrayObj.sort((a, b) => {
                return a[1]['order'] - b[1]['order']
            })
            return ArrayObj
        },
        // 聯絡人在線數量
        onlineDoc() {
            var onlineTotal = 0
            _.find(this.contacts, function (o) {
                if (o.num == true) {
                    onlineTotal = onlineTotal + 1
                }
            })
            return onlineTotal
        },
        // 聯絡人總數量
        contactTotal() {
            return this.contacts.length
        },
        menuActive: {
            get() {
                if (this.windowWidth > 600) {
                    return true
                } else {
                    return this.menu
                }
            },
            set(val) {},
        },
    },
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem('partnerid') == 31) next({ path: '/members' })
        else next()
    },
    created() {
        // 預設值
        const savedUnit = localStorage.getItem('selectedUnit')
        this.unit = savedUnit ? JSON.parse(savedUnit) : null

        this.getMemberFilter()
        this.getDocList()
    },
    methods: {
        // 取會員
        getMemberList: debounce(function (event, page = 1) {
            var eids = this.unit ? this.unit : this.getAllUnitId()
            if (!eids) {
                this.membersList = []
                this.total = 0
                return
            }
            this.$vs.loading()
            this.page = page
            let payload = {
                partner_id: this.partnerid,
                page: this.page,
                limit: this.limit,
                eids: eids,
            }
            if (this.query) payload.query = this.query
            if (this.tags) payload.crm_tags = this.tags
            if (this.sicks.id) payload.disease = this.sicks
            getMemberList(payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.membersList = res.data.data.items
                    this.total = res.data.data.total
                }
                this.$vs.loading.close()
            })
        }, 200),
        // 取公司和群組清單
        getMemberFilter() {
            var payload = {
                partner_id: this.partnerid,
            }
            getMemberFilter(payload).then((res) => {
                if (res.data.status === 'OK') {
                    this.unitList = res.data.data.enterprise_list
                    this.crmTagsList = res.data.data.crm_tags
                    this.sickList = res.data.data.sick_list
                    this.getMemberList()
                }
            })
        },
        // 開啟該病人的病歷
        goToMr(data, index = null, tabName = null) {
            if (data.authorize != 1) return
            let patientJson = {
                name: data.name,
                pid: data.pid,
                account: data.account,
            }
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)

            var routerPayload = {
                path: '/emr2/' + data.pid + '/0',
            }
            if (!index) {
                routerPayload.path += '?index=base'
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '?index=' + index
            if (!tabName) {
                this.$router.push(routerPayload)
                return
            }

            routerPayload.path += '&tab=' + tabName
            this.$router.push(routerPayload)
        },
        // 取所有單位id
        getAllUnitId() {
            let rs = ''
            this.unitList.forEach((v) => {
                rs += v.enterprise_id + ','
            })
            return _.trim(rs, ',')
        },
        // 取性別
        getSex(val) {
            const statusMap = {
                0: '-',
                1: this.$t('membersMgmt.male'),
                2: this.$t('membersMgmt.female'),
            }
            return statusMap[val]
        },
        // 取年齡
        getAge(val) {
            if (val === '0000-00-00' || !val) {
                return '-'
            } else {
                const ageMS = Date.parse(Date()) - Date.parse(val)
                const age = new Date()
                age.setTime(ageMS)
                const ageYear = age.getFullYear() - 1970
                return ageYear
            }
        },
        // 開關前往病歷的toggle
        showGoToMrToggle(data) {
            if (data.authorize != 1) return
            this.goToMrToggle = !this.goToMrToggle
        },
        // 開啟掛號視窗
        showApptPopup(index, data) {
            if (data.authorize != 1) return
            document.body.style.overflow = 'hidden'
            this.apptMode = index
            this.patient.pid = parseInt(data.pid)
            this.patient.name = data.fullname
            this.openApptPopup = true
        },
        // 關閉掛號視窗
        closeApptPopup(v) {
            document.body.style.overflow = 'visible'
            this.openApptPopup = v
        },
        // 開啟訊息視窗
        showChatPopup(mid, name) {
            document.body.style.overflow = 'hidden'
            this.midChat = mid
            this.nameChat = name
            this.openChatPopup = true
        },
        // 關閉訊息視窗
        closeChatPopup(v) {
            document.body.style.overflow = 'visible'
            this.midChat = 0
            this.openChatPopup = v
        },
        // 開啟 醫療影像上傳視窗
        showUploadPopup(data) {
            if (data.authorize != 1) return
            document.body.style.overflow = 'hidden'
            this.pidUpload = data.pid
            this.nameUpload = data.fullname
            this.openUploadPopup = true
        },
        // 關閉 醫療影像上傳視窗
        closeUploadPopup() {
            document.body.style.overflow = 'visible'
            this.openUploadPopup = false
        },
        // 開啟新增客服案件視窗
        showCasePopup(pid, name) {
            document.body.style.overflow = 'hidden'
            this.pidCase = pid
            this.pnameCase = name
            this.openCasePopup = true
        },
        // 關閉新增客服案件視窗
        closeCasePopup() {
            document.body.style.overflow = 'visible'
            this.pidCase = 0
            this.pnameCase = ''
            this.openCasePopup = false
        },
        // 開啟選擇通話對象視窗
        openCallPopup(data) {
            console.log(data)
            if (data.authorize != 1) return
            document.body.style.overflow = 'hidden'
            // this.callPayload = {
            //     "spid": data.pid,
            //     "name": data.fullname,
            // }
            this.callPid = data.pid
            // this.callPopupPayload = {
            //     "pid": data.pid,
            //     "name": data.fullname,
            // }
            this.callPopup = true
        },
        // 關閉選擇通話對象視窗
        closeCallPopup() {
            document.body.style.overflow = 'visible'
            this.callPopup = false
        },
        //開啟推薦商品視窗
        openProductPopup(pid, id, name) {
            this.recommandPid = pid
            this.recommsnid = id
            this.recommpname = name
            document.body.style.overflow = 'hidden'
            this.productPopupActive = true
        },
        //關閉推薦商品視窗
        productClose() {
            document.body.style.overflow = 'visible'
            this.productPopupActive = false
        },
        // 醫療影像語系
        geti18n(items) {
            const statusMap = {
                tw: items.tw,
                cn: items.cn,
                en: items.en,
            }
            return statusMap[this.$i18n.locale]
        },
        // 直接打電話 轉成href格式
        getPhone(number) {
            number = number.slice(2, 10)
            number = 'tel:+886-9' + number
            return number
        },
        // 取合作夥伴的醫事人員
        getDocList() {
            var payload = {
                did: this.did,
                partnerid: this.partnerid,
                query: '',
            }
            QueryDoctorListByPartner(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.contacts = _.cloneDeep(res.data.data)
                }
            })
        },
        // 手機版 開啟篩選介面
        showFilter() {
            this.menu = !this.menu
        },
        // 篩選回到預設
        reset() {
            this.query = null
            this.unit = null
            this.tags = null
            this.sicks.id = null
        },
        // 取單位名字
        getUnitName(id) {
            const unit = _.find(this.unitList, { enterprise_id: id })
            return unit ? unit.name : null
        },
        // 取病名
        getSickName(id) {
            return this.lang == 'en' ? _.find(this.sickList, { mh_item_id: id }).ename : _.find(this.sickList, { mh_item_id: id }).note
        },
        // 通話測試
        // call(is_doctor, mid){
        //     // console.log("call test", data)
        //     // const payload = {
        //     //     "is_doctor": is_doctor,
        //     //     "to_mid": mid
        //     // }
        //     // calltest(payload)
        //     const payload = {
        //         "is_doctor": is_doctor,
        //         "to_mid": mid
        //     }
        //     call(payload).then(res => {
        //         if(res.data.status == 'OK') {
        //             this.$store.commit('SET_CALL_ID', res.data.callId)
        //             this.$store.commit('SET_REMOTE_ID', mid)
        //             this.$store.commit('SET_VIDEO_ACTIVE', true)
        //             this.$emit("closePopup")
        //         }
        //     })
        //     // this.$store.dispatch('toggleRightPanel', true)
        //     this.closeCallPopup()
        // },
    },
    beforeRouteLeave(to, from, next) {
        this.openChatPopup = false
        this.openApptPopup = false
        this.openUploadPopup = false
        this.openCasePopup = false
        this.callPopup = false
        next()
    },
}
</script>
